import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/join",
  },
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/join/index.vue"),
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/invite/index.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () => import("@/views/invite/success.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () => import("@/views/invite/password.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});
export default router;
