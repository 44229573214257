import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import naiveUI from "naive-ui";

import { store } from "./store";
import "@/assets/styles/common.less";
const app = createApp(App);
app.use(naiveUI);

// 注册路由
app.use(router);

// 挂载状态管理
app.use(store);

app.config.warnHandler = (msg, instance, trace) => {};

app.mount("#app");
